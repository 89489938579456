import React, { useState, useRef } from 'react';
import './Formular.css';
import { useForm, SubmitHandler } from 'react-hook-form';
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';
import { handleClickScrollToTop } from '../../App';

export interface IFormularInput {
    name: string;
    email: string;
    phoneNumber: string;
    nameFirma: string;
    street: string;
    streetNumber: string;
    city: string;
    psc: string;
    country: string;
    note?: string;
    ico?: string;
    icoFirma: string;
    period: string;
    package: string;
    price: string;
}

function Formular(props: { package: string; price: string; setIsOrdered: React.Dispatch<React.SetStateAction<boolean>> }) {
    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm<IFormularInput>({});

    const form: React.MutableRefObject<any> = useRef(null);
    // form.current.newVariable = 'value'; // Add a new variable to form.current

    const sendEmail = (e: { preventDefault: () => void }) => {

        e.preventDefault(); // prevents the page from reloading when you hit “Send”

        emailjs.sendForm('service_y8l1fy6', 'template_c4svr0i', form.current, 'Hs4tzXCk047cj8W5n').then(
            (result) => {
                console.log(result);
            },
            (error) => {
                console.log(error);
            }
        );

        props.setIsOrdered(true);
    };

    const navigate = useNavigate();

    const navigateToPage = (url: string) => {
        navigate(url);
    };

    return (
        <div>
            <div className="formular-container" id="formular">
                <form ref={form} onSubmit={sendEmail} className="form">
                    <input
                        type="text"
                        id="packge"
                        className='enable-display'
                        value={props.package}
                        {...register('package')}
                    />
                    <input
                        type="text"
                        id="price"
                        className='enable-display'
                        value={props.price}
                        {...register('price')}
                    />
                    <div className="grid-box">
                        <div className="x-area">
                            <div className="title title-form">Údaje objednávateľa</div>

                            <div className="form-name">
                                <input
                                    type="text"
                                    id="name"
                                    required
                                    placeholder="Názov firmy / meno a priezvisko"
                                    className="info-input"
                                    {...register('name', { required: true, maxLength: 100, pattern: /[A-Za-z]* [A-Za-z]*/ })}
                                />
                            </div>
                            <input
                                type="text"
                                id="ico"
                                placeholder="IČO (nepovinné)"
                                className="info-input"
                                {...register('ico', { required: false })}
                            />

                            <div className="form-email">
                                <input
                                    type="email"
                                    id="email"
                                    required
                                    placeholder="Email"
                                    className="info-input"
                                    {...register('email', { required: true, maxLength: 100, pattern: /[A-Za-z1-9]*@[A-Za-z1-9]*/ })}
                                />
                            </div>

                            <div className="form-tel">
                                <input
                                    type="tel"
                                    id="tel"
                                    placeholder="Telefón"
                                    className="info-input"
                                    {...register('phoneNumber', {
                                        required: true,
                                        pattern: /^[0-9\b]+$/
                                    })}
                                />
                            </div>
                            {/* <div className="title title-form">Adresa objednávateľa</div> */}
                            <div className="form-street">
                                <input
                                    type="text"
                                    id="street"
                                    required
                                    placeholder="Ulica"
                                    className="info-input"
                                    {...register('street', { required: true })}
                                />
                            </div>
                            <div className="form-streetNumber">
                                <input
                                    type="text"
                                    id="streetNum"
                                    required
                                    placeholder="Číslo"
                                    className="info-input"
                                    {...register('streetNumber', { required: true })}
                                />
                            </div>
                            <div className="form-city">
                                <input
                                    type="text"
                                    id="city"
                                    required
                                    placeholder="Mesto"
                                    className="info-input"
                                    {...register('city', { required: true })}
                                />
                            </div>
                            <div className="form-psc">
                                <input
                                    type="text"
                                    id="psc"
                                    required
                                    placeholder="PSČ"
                                    className="info-input"
                                    {...register('psc', { required: true })}
                                />
                            </div>
                            <div className="form-example">
                                <input
                                    type="text"
                                    id="country"
                                    required
                                    placeholder="Štát"
                                    className="info-input"
                                    {...register('country', { required: true })}
                                />
                            </div>

                        </div>

                        <div className="y-area">
                            <div className="title title-form">Údaje klienta</div>
                            <div className="form-nameFirma">
                                <input
                                    type="text"
                                    id="firma"
                                    required
                                    placeholder="Názov firmy pre virtuálne sídlo"
                                    className="info-input"
                                    {...register('nameFirma', { required: true, maxLength: 100 })}
                                />
                            </div>
                            <input
                                type="text"
                                id="icoFirma"
                                required
                                placeholder="IČO / ak ide o novú spoločnosť zadajte 0"
                                className="info-input"
                                {...register('icoFirma', { required: true })}
                            />


                            <div className="title title-form">Ostatné</div>
                            <div className="form-period">
                                <input
                                    type="text"
                                    id="period"
                                    required
                                    placeholder="Obdobie (napr. 1 rok, neurčito)"
                                    className="info-input"
                                    {...register('period', { required: true, maxLength: 100 })}
                                />
                            </div>
                            <textarea
                                id="note"
                                placeholder="Správa pre príjemcu (nepovinný údaj, max. 100 znakov)"
                                className="info-input height-textArea"
                                maxLength={100}
                                {...register('note', { required: false, maxLength: 100 })}
                            />
                            <div className="form-checkbox">
                                <div className="checkbox">
                                    <input type="checkbox" id="asistantChoice" name="assistant" required={true} />
                                    <label htmlFor="asistantChoice">
                                        Súhlasím s{' '}
                                        <div
                                            className="anchor-data"
                                            onClick={() => {
                                                navigateToPage('/ochrana-udajov');
                                                handleClickScrollToTop();
                                            }}
                                        >
                                            ochranou osobných údajov
                                        </div>
                                    </label>
                                </div>
                                <div className="checkbox">
                                    <input type="checkbox" id="asistantChoice2" name="assistant2" required={true} />
                                    <label htmlFor="asistantChoice2">
                                        Súhlasím s{' '}
                                        <div
                                            className="anchor-data"
                                            onClick={() => {
                                                navigateToPage('/obchodne-podmienky');
                                                handleClickScrollToTop();
                                            }}
                                        >
                                            obchodnými podmienkami
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <button className="click info-text-click">Mám záujem</button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    );
}

export default Formular;
