import React from 'react';
import './PersonalDataPage.css';
import Menu from '../menu/Menu';
import CookieConsent from 'react-cookie-consent';

function PersonalDataPage() {
    return (
        <div>
            <div className="personalData-box">
                <div className="main-title title-light">Ochrana osobných údajov</div>
                <div className="subtitle">I. Identifikačné údaje Poskytovateľa</div>
                <p className="personalData-text">
                    LUNEMA property, s. r. o.
                    <br />
                    IČO: 55319971, DIČ: 2121947421, IČ DPH: SK2121947421 podľa §7a
                    <br />
                    sídlo: Hviezdoslavova 281/18, 965 01 Žiar nad Hronom, Slovenská republika
                    <br />
                    zápis spoločnosti: Obchodný register Okresný súd Banská Bystrica, oddiel: Sro, vložka č. 45985/S
                    <br />
                    IBAN (číslo účtu): SK83 8330 0000 0022 0257 1003, BIC: FIOZSKBA
                    <br />
                    konajúca za spoločnosť: Ing. Mariana Hrmová – konateľ
                    <br />
                    kontakt: lunema.sro@gmail.com , +421907149549
                </p>
                <div className="subtitle">II. Účel spracúvania osobných údajov</div>
                <p className="personalData-text">
                    Ako Poskytovateľ získavame a uchovávame o vás len tie informácie, ktoré sme od vás získali s vašim súhlasom. Ide
                    predovšetkým o osobné údaje z objednávok. Účel takéhoto spracúvania osobných údajov vyplýva hlavne zo zákonov platných
                    na území Slovenskej republiky: Zákon o účtovníctve, Občiansky zákonník, Obchodný zákonník.
                    <br />
                    <br />
                    Získané osobné údaje na základe vašej objednávky nespracúvame ďalej na reklamné, marketingové, štatistické či
                    preferenčné účely.
                </p>
                <div className="subtitle">III. Právny základ spracúvania osobných údajov</div>
                <p className="personalData-text">
                    Poskytovateľ prehlasuje, že bezpečnosť je u neho na prvom mieste a preto ochranu osobných údajov berie vážne a dôverne
                    s nimi pracuje.
                    <br />
                    <br />
                    Poskytovateľ spracúva osobné údaje fyzických osôb v rámci predzmluvného a/alebo zmluvného vzťahu s Objednávateľom za
                    účelom objednávky a poskytnutia služby/služieb v súlade so všetkými príslušnými právnymi predpismi, najmä:
                </p>
                <ul className="personalData-text first-ul">
                    <li className="personalData-item">
                        Nariadením Európskeho parlamentu a Rady (EÚ) 2016/679 z 27. apríla 2016 o ochrane fyzických osôb pri spracúvaní
                        osobných údajov a o voľnom pohybe takýchto údajov
                    </li>
                    <li className="personalData-item">
                        Zákonom č. 18/2018 Zb. o ochrane osobných údajov a o zmene a doplnení niektorých zákonov
                    </li>
                    <li className="personalData-item">
                        Vyhláškou Úradu na ochranu osobných údajov SR č. 158/2018 Zb. o postupe pri spracovaní vplyvu na ochranu osobných
                        údajov
                    </li>
                </ul>
                <p className="personalData-text">
                    Poskytovateľ spracúva osobné údaje Objednávateľa (ak je fyzickou osobou), spoločníkov, členov orgánov a iných fyzických
                    osôb, poskytnutých Objednávateľom v súvislosti s poskytovaním služby/služieb na právnom základe v rozsahu nevyhnutnom na
                    poskytnutie služby/služieb, ktorý zodpovedá zákonným požiadavkám podľa platných a účinných všeobecne záväzných právnych
                    predpisov k poskytnutiu služby/služieb. Na tento účel nie je potrebné mať písomný súhlas dotknutej osoby.
                    <br />
                    <br />
                    Doba uchovávania osobných údajov na právnom základe je v súlade s platnou legislatívou Slovenskej republiky.
                </p>
                <div className="subtitle">IV. Povinnosti Objednávateľa</div>
                <p className="personalData-text">
                    Objednávateľ je povinný poskytnúť Poskytovateľovi správne a aktuálne osobné údaje, spolu s písomným súhlasom dotknutých
                    osôb, ktorých osobné údaje Objednávateľ poskytuje Poskytovateľovi za účelom poskytnutia služby/služieb. Neposkytnutie
                    príslušných súhlasov na výzvu Poskytovateľa je porušením zmluvných podmienok Objednávateľa a Poskytovateľ nie je povinný
                    poskytnúť objednanú služby/služby.
                    <br />
                    <br />
                    Objednávateľ pre účely objednávky najčastejšie poskytuje tieto osobné údaje: meno, priezvisko, titul, bydlisko, poštovú
                    adresu, telefónne číslo a mailovú adresu.
                </p>

                <div className="subtitle">V. Práva dotknutej osoby, ktorej osobné údaje sa spracovávajú</div>
                <p className="personalData-text">Dotknutá oprávnená osoba má:</p>
                <ul className="personalData-text first-ul">
                    <li className="personalData-item">Právo požadovať prístup k jej osobným údajom</li>
                    <li className="personalData-item">Právo požadovať informáciu, aké osobné údaje sú spracovávané</li>
                    <li className="personalData-item">Právo na opravu/aktualizáciu osobných údajov</li>
                    <li className="personalData-item">Právo na vymazanie osobných údajov</li>
                    <li className="personalData-item">Právo na obmedzenie spracúvania osobných údajov</li>
                    <li className="personalData-item">Právo namietať spracúvanie osobných údajov</li>
                    <li className="personalData-item">Právo na prenosnosť osobných údajov</li>
                    <li className="personalData-item">Právo požadovať kópiu spracovávaných osobných údajov</li>
                    <li className="personalData-item">Právo vzniesť u námietku Poskytovateľovi ohľadom spracúvania osobných údajov</li>
                    <li className="personalData-item">Právo podať sťažnosť na Úrade na ochranu osobných údajov SR</li>
                    <li className="personalData-item">Právo na súdnu ochranu, ak si myslí, že práva boli porušené</li>
                </ul>
                <p className="personalData-text space-top">
                    Dotknutá oprávnená osoba má právo kedykoľvek odvolať svoj súhlas so spracúvaním svojich osobných údajov, ak takýto
                    poskytli a zákon to dovoľuje.
                </p>
                <div className="subtitle">VI. Poskytnutie osobných údajov cudzím osobám</div>
                <p className="personalData-text">
                    Poskytovateľ sa zaväzuje, že zabezpečí, aby všetky ním určené oprávnené osoby boli pred spracúvaním osobných údajov
                    poučené o zásadách spracúvania osobných údajov a neposkytli takto spracúvané osobné údaje cudzím osobám. Poskytovateľ
                    zároveň informuje, že spracované osobné údaje môžu byť na vyžiadanie poskytnuté i orgánom verejnej moci, ako i tretím
                    osobám, ktoré poskytujú služby alebo činnosti súvisiace s plnením objednanej služby/služieb.
                </p>
                <div className="subtitle">VII. Povolené operácie Poskytovateľa a zásada minimalizácie</div>
                <p className="personalData-text">
                    Povolenými operáciami Poskytovateľa s osobnými údajmi sú: zaznamenávanie, usporadúvanie, vyhľadávanie, prehliadanie,
                    kopírovanie, likvidácia.
                    <br />
                    <br />
                    Poskytovateľ pri spracúvaní osobných údajov dotknutých osôb uplatňuje zásadu minimalizácie osobných údajov, podľa ktorej
                    spracúvané osobné údaje musia byť primerané, relevantné a obmedzené na nevyhnutný rozsah daný účelom, na ktorý sa
                    spracúvajú, pričom má povinnosť, okrem iného:
                </p>
                <ul className="personalData-text first-ul">
                    <li className="personalData-item">Spracúvať osobné údaje len na určené účely</li>
                    <li className="personalData-item">
                        Spracúvať len také osobné údaje, ktoré rozsahom a obsahom zodpovedajú určenému účelu a sú nevyhnutné pre jeho
                        dosiahnutie
                    </li>
                    <li className="personalData-item">
                        Udržiavať osobné údaje získané na rozdielne účely oddelene a zabezpečiť, aby osobné údaje boli spracúvané iba
                        spôsobom, ktorý zodpovedá účelu, na ktorý boli zhromaždené
                    </li>
                    <li className="personalData-item">
                        Spracúvať iba správne, kompletné a aktuálne osobné údaje vo vzťahu k účelu ich spracúvania a naložiť s nesprávnymi a
                        nekompletnými údajmi v súlade so zákonom o ochrane osobných údajov
                    </li>
                    <li className="personalData-item">
                        Spracúvať osobné údaje v súlade s dobrými mravmi a konať spôsobom, ktorý nie je v rozpore so Zákonom o ochrane
                        osobných údajov, ani inými právnymi predpismi a ani ich neobchádza.
                    </li>
                </ul>
                <div className="subtitle">VIII. Kontaktné údaje v prípade ďalších informácií</div>
                <p className="personalData-text">
                    V prípade akýchkoľvek požiadaviek a/alebo otázok k ochrane osobných údajov, k vašim právam v zmysle vyššie uvedeného
                    zákona, vyhlášky a nariadenia, možnosti prístupu k vašim osobným údajom, spôsobu odvolania súhlasu k spracovaniu
                    osobných údajov a iné, kontaktujte nás mailom: lunema.sro@gmail.com.
                </p>
                <p className="personalData-text space-top">
                    Zverejnené 2.1.2024, s účinnosťou od 2.1.2024, na dobu neurčitú.
                </p>
            </div>
            <CookieConsent
                location="bottom"
                buttonText="Rozumiem"
                disableStyles={true}
                style={{
                    background: '#2B373B',
                    color: 'var(--main-color-orange)',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'fixed',
                    width: '100%',
                    alignItems: 'center',
                    padding: '0.5rem 0'
                }}
                buttonStyle={{
                    backgroundColor: 'var(--main-color-orange)',
                    textAlign: 'center',
                    color: 'var(--main-color-brown)',
                    fontSize: '1rem',
                    width: '5.6rem',
                    height: '1.7rem',
                    border: 'none',
                    borderRadius: '0.5rem',
                    margin: '0.5rem 0 0.2rem 0',
                    cursor: 'pointer'
                }}
                expires={365}
            >
                Prevádzkovateľ oboznamuje Užívateľa i Návštevníka, že webová stránka používa len základné (funkčné) súbory Cookies. Bez
                týchto Cookies nemôžeme poskytovať služby, ktoré tvoria základ našej stránky. Pokiaľ tieto Cookies zakážete, nebudeme môcť
                zabezpečiť bezchybný chod stránky. Iné Cookies nezbierame.
            </CookieConsent>
        </div>
    );
}

export default PersonalDataPage;
