import React from "react";
import { handleClickScrollContact, handleClickScrollForm, handleClickScrollPriceList, handleClickScrollServices, handleClickScrollSuccess, handleClickScrollToTop } from "../../App";
import './Menu.css';
import { useNavigate } from "react-router-dom";

function Menu(props: {mobileMenu: boolean, setMobileMenu: React.Dispatch<React.SetStateAction<boolean>> }){
  const navigate = useNavigate();

  const navigateToPage = (url: string) => {
    navigate(url);
  };

  return(
    <div className={props.mobileMenu? 'menu-container menu-container--mobile' : 'menu-container'}>

        <div className={props.mobileMenu? 'homePage-menuContainer homePage-menuContainer--nonShadow ' : 'homePage-menuContainer'} >
          <div className='homePage-item--logo homePage-item--dot'><img className='homePage-logo ' src={require('../../images/edited-logo1.webp')} alt='logo'/></div>
          <menu className='homePage-menu'>
            <li className='homePage-item homePage-item--desctop'><button className='homePage-item-button' onClick={async () => {await navigateToPage("/"); handleClickScrollToTop();}}>Virtuálne sídlo</button></li>
            <li className='homePage-item homePage-item--desctop'><button className='homePage-item-button' onClick={async () => {await navigateToPage("/"); handleClickScrollServices();}}>Služby</button></li>
            <li className='homePage-item homePage-item--desctop'><button className='homePage-item-button' onClick={async () => {await navigateToPage("/"); handleClickScrollPriceList();}}>Cenník</button></li>
            <li className='homePage-item homePage-item--desctop'><button className='homePage-item-button' onClick={async () => {await navigateToPage("/"); handleClickScrollSuccess();}}>Recenzie</button></li>
            <li className='homePage-item homePage-item--desctop'><button className='homePage-item-button--dark' onClick={async () => {await navigateToPage("/"); handleClickScrollContact();}}>Kontakty</button></li>
          </menu>
          <div className="homePage-item--menuButton">
            <button className="homePage-menuButton" onClick={() => props.setMobileMenu(!props.mobileMenu)}>{props.mobileMenu? <span className="material-symbols-outlined">close</span> : <span className="material-symbols-outlined">menu</span>}</button>
          </div>
        </div>
          <menu className={props.mobileMenu? 'homePage-menu homePage-menu--mobile' : 'unable-display'}>
            <li className='homePage-item homePage-item--mobile'><button className='homePage-item-button' onClick={async () => {await navigateToPage("/"); handleClickScrollToTop(); props.setMobileMenu(!props.mobileMenu)}}>Virtuálne sídlo</button></li>
            <li className='homePage-item homePage-item--mobile'><button className='homePage-item-button' onClick={async () => {props.setMobileMenu(!props.mobileMenu); await navigateToPage("/"); handleClickScrollServices();}}>Služby</button></li>
            <li className='homePage-item homePage-item--mobile'><button className='homePage-item-button' onClick={async () => {props.setMobileMenu(!props.mobileMenu); await navigateToPage("/"); handleClickScrollPriceList();}}>Cenník</button></li>
            <li className='homePage-item homePage-item--mobile'><button className='homePage-item-button' onClick={async () => {props.setMobileMenu(!props.mobileMenu); await navigateToPage("/"); handleClickScrollSuccess();}}>Recenzie</button></li>
            <li className='homePage-item homePage-item--mobile'><button className='homePage-item-button--dark' onClick={async () => {props.setMobileMenu(!props.mobileMenu); await navigateToPage("/"); handleClickScrollContact(); }}>Kontakty</button></li>
          </menu>
      </div>
  )
}

export default Menu;