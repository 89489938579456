import React, { useState } from 'react';
import './App.css';
import Menu from './components/menu/Menu';
import HomePage from './components/homePage/HomePage';
import Services from './components/servicesCom/Services';
import PriceList from './components/priceList/PriceList';
import Quotes from './components/quotes/Quotes';
import Foot from './components/foot/Foot';
import Ad from './components/Ad/Ad';
import CookieConsent from 'react-cookie-consent';

export function handleClickScrollPriceList() {
    const element = document.getElementById('priceList');
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
}

export const handleClickScrollServices = () => {
    const element = document.getElementById('services');
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
};

export const handleClickScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const handleClickScrollForm = () => {
    const element = document.getElementById('form');
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
};

export const handleClickScrollContact = () => {
    const element = document.getElementById('end');
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
};

export const handleClickScrollSuccess = () => {
    const element = document.getElementById('qu');
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
};

function App() {
    const [click, setClick] = useState(false);
    const [mobileMenu, setMobileMenu] = useState(false);

    return (
        <div className={mobileMenu ? 'App App--mobile' : 'App'} id="app">
            <Menu mobileMenu={mobileMenu} setMobileMenu={setMobileMenu} />

            <div className={mobileMenu ? 'unable-display' : 'all-height'}>
                <HomePage />
            </div>

            <div className={mobileMenu ? 'unable-display' : ''} id="services">
                <Services click={click} setClick={setClick} />
            </div>

            <div className={mobileMenu ? 'unable-display' : ''} id="priceList">
                <PriceList click={click} />
            </div>

            <div className={mobileMenu ? 'unable-display' : ''} id="quotes">
                <Quotes />
            </div>

            <div className={mobileMenu ? 'unable-display' : ''}>
                <Ad />
            </div>

            <div className={mobileMenu ? 'unable-display' : ''}>
                <Foot bottom={false} />
            </div>

            <CookieConsent
                location="bottom"
                buttonText="Rozumiem"
                disableStyles={true}
                style={{
                    background: '#2B373B',
                    color: 'var(--main-color-orange)',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'fixed',
                    width: '100%',
                    alignItems: 'center',
                    padding: '0.5rem 0'
                }}
                buttonStyle={{
                    backgroundColor: 'var(--main-color-orange)',
                    textAlign: 'center',
                    color: 'var(--main-color-brown)',
                    fontSize: '1rem',
                    width: '5.6rem',
                    height: '1.7rem',
                    border: 'none',
                    borderRadius: '0.5rem',
                    margin: '0.5rem 0 0.2rem 0',
                    cursor: 'pointer'
                }}
                expires={365}
            >
                Prevádzkovateľ oboznamuje Užívateľa i Návštevníka, že webová stránka používa len základné (funkčné) súbory Cookies. Bez
                týchto Cookies nemôžeme poskytovať služby, ktoré tvoria základ našej stránky. Pokiaľ tieto Cookies zakážete, nebudeme môcť
                zabezpečiť bezchybný chod stránky. Iné Cookies nezbierame.
            </CookieConsent>
        </div>
    );
}

export default App;
