import React from 'react';
import kompas from '../../images/kompas-logo.webp';
import './Ad.css';

function Ad() {
    return (
        <div className="ad-box">
            <a className="ad-anchor" href="https://cestovatelskykompas.sk/" target="_blank">
                <img className="ad-image" src={kompas} alt="cestovateľský kompas" />
                <div className="ad-ad">reklama</div>
            </a>
        </div>
    );
}

export default Ad;
