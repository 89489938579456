import React from 'react';
import { useRouteError } from "react-router-dom";
import "./ErrorPage.css";

function ErrorPage(){
  const error:any = useRouteError();
  console.error(error);

  return (
    <div className="error-box" id='error-page'>
      <h1 className='error-title'>Oops!</h1>
      <p className='error-text'>Prepáčte, niečo sa pokazilo.</p>
      <p>
        <i className='error-info'>{error.statusText || error.message}</i>
      </p>
    </div>
  )
}

export default ErrorPage;