import React from 'react';
import MobileImg from '../../images/telephone.svg';
import EmailImg from '../../images/email.svg';
import WebImg from '../../images/domain.svg';
import FbImage from '../../images/facebook.svg';
import './Foot.css';
import { useNavigate } from 'react-router-dom';
import { handleClickScrollToTop } from '../../App';

function Foot(props: { bottom: boolean }) {
    const navigate = useNavigate();

    const navigateToPage = (url: string) => {
        navigate(url);
        handleClickScrollToTop();
    };

    return (
        <footer className={props.bottom ? 'footer footer-bottom' : 'footer'} id="end">
            <div className="footer-container">
                <div className="footer-box01">
                    <span className="footer-title">Kontakty</span>
                    <ul className="footer-list">
                        <li className="footer-item orange">LUNEMA property, s.r.o.</li>
                        <li className="footer-item">
                            <img src={MobileImg} className="footer-image" />
                            +421 907 149 549
                        </li>
                        <li className="footer-item">
                            <img src={EmailImg} className="footer-image"/> lunema.sro@gmail.com
                        </li>
                        <li className="footer-item">
                            <img src={WebImg} className="footer-image" /> <a href="https://www.lunema.sk/" target="_blank">www.lunema.sk</a>
                        </li>
                        <li className="footer-item">
                            <img src={FbImage} className="footer-image" />{' '}
                            <a href="https://www.facebook.com/lunemaproperty" target="_blank">www.facebook.com/lunemaproperty</a>
                        </li>
                    </ul>
                </div>
                <div className="footer-box02">
                    <span className="footer-title box02-title">Užitočné odkazy</span>
                    <ul className="footer-list links-list">
                        <li onClick={() => navigateToPage('/obchodne-podmienky')} className="footer-item footer-item--underline">
                            Obchodné podmienky
                        </li>
                        <li onClick={() => navigateToPage('/reklamacne-podmienky')} className="footer-item footer-item--underline">
                            Reklamačné podmienky
                        </li>
                        <li onClick={() => navigateToPage('/ochrana-udajov')} className="footer-item footer-item--underline">
                            Ochrana osobných údajov
                        </li>
                        <li onClick={() => navigateToPage('/cookies')} className="footer-item footer-item--underline">
                            Cookies
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
}

export default Foot;
