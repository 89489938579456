import React from 'react';
import './PersonalDataPage.css';
import CookieConsent from 'react-cookie-consent';

function BusinessCondPage() {
    return (
        <div>
            <div className="personalData-box">
                <div className="main-title title-light">Všeobecné obchodné podmienky</div>
                <div className="subtitle">I. Úvodné ustanovenia</div>
                <p className="personalData-text">Tieto Všeobecné obchodné podmienky poskytnutia služby/služieb (VOP) predstavujú návrh:</p>
                <ul className="personalData-text first-ul">
                    <li className="personalData-item">
                        Poskytovateľa na uzavretie zmluvy, ktorý bude/bol prijatý od Objednávateľa odoslaním objednávky a následným
                        doručením potvrdenia o úspešnom odoslaní objednávky od Objednávateľa Poskytovateľovi. Takto prijatý návrh na
                        uzavretie zmluvy o poskytovaní sídla (vrátane balíku služieb k virtuálnemu sídlu v súlade s aktuálne zverejneným
                        cenníkom na internetovej stránke) je podľa ustanovení § 269 ods. 2 zákona č. 513/1991 Zb. Obchodný zákonník v znení
                        aktuálnych predpisov a iných aplikovateľných právnych predpisov.
                    </li>
                    <li className="personalData-item">
                        Poskytovateľa na uzavretie zmluvy, ktorý bude/bol prijatý od Objednávateľa odoslaním objednávky a následným
                        doručením potvrdenia o prijatí objednávky od Poskytovateľa Objednávateľovi v rozsahu ostatných ponúkaných služieb
                        uvedených na internetovej stránke (patria sem všetky ostatné služby, okrem poskytnutie virtuálneho sídla a k nemu
                        prináležiace balíky služieb).
                    </li>
                </ul>

                <div className="subtitle">II. Definície pojmov a druhy služieb</div>
                <p className="personalData-text">
                    <span className="underline italic">Poskytovateľ:</span>
                    <br />
                    LUNEMA property, s. r. o.
                    <br />
                    IČO: 55319971, DIČ: 2121947421, IČ DPH: SK2121947421 podľa §7a
                    <br />
                    sídlo: Hviezdoslavova 281/18, 965 01 Žiar nad Hronom, Slovenská republika
                    <br />
                    zápis spoločnosti: Obchodný register Okresný súd Banská Bystrica, oddiel: Sro, vložka č. 45985/S
                    <br />
                    IBAN (číslo účtu): SK83 8330 0000 0022 0257 1003, BIC: FIOZSKBA
                    <br />
                    konajúca za spoločnosť: Ing. Mariana Hrmová – konateľ
                    <br />
                    kontakt: lunema.sro@gmail.com , +421907149549
                    <br />
                </p>
                <p className="personalData-text space-top">
                    <span className="underline italic">Objednávateľ:</span>
                    <br />
                    Fyzická alebo právnická osoba, ktorá si objedná službu/služby u Poskytovateľa prostredníctvom elektronickej komunikácie
                    s Poskytovateľom (napr. prostredníctvom internetovej stránky, mailom), osobne u Poskytovateľa, telefonicky a/alebo iným
                    preukázateľným spôsobom.
                </p>
                <p className="personalData-text space-top">
                    <span className="underline italic">Užívateľ či Návštevník:</span>
                    <br />
                    Fyzická osoba, ktorá si prezerá našu internetovú stránku.
                </p>
                <p className="personalData-text space-top">
                    <span className="underline italic">Služby:</span>
                    <br />
                    Objednávateľ berie na vedomie, že týmito VOP sú upravené služby, ktoré na svojej internetovej stránke ponúka
                    Poskytovateľ a to v rozsahu:
                </p>
                <ul className="personalData-text first-ul">
                    <li className="personalData-item">
                        Prenájom virtuálneho sídla (vrátane balíku služieb prináležiaci k sídlu)
                        <ul>
                            <li className="personalData-item">
                                Prenájom sídla – tzv. virtuálne sídlo je služba, ktorú poskytujeme firmám, alebo živnostníkom na adrese: Dr.
                                Jánského 477/4, 965 01 Žiar nad Hronom, SK
                            </li>
                        </ul>
                    </li>
                    <li className="personalData-item">
                        Účtovníctvo
                        <ul>
                            <li className="personalData-item">Vedenie účtovníctva pre fyzické a právnické osoby</li>
                            <li className="personalData-item">Spracovanie daňových priznaní</li>
                            <li className="personalData-item">Ekonomicko – účtovné poradenstvo</li>
                            <li className="personalData-item">Správa bytových a nebytových objektov</li>
                        </ul>
                    </li>
                    <li className="personalData-item">
                        Virtuálna asistentka
                        <ul>
                            <li className="personalData-item">Administratívne práce</li>
                            <li className="personalData-item">Pomoc na úradoch</li>
                            <li className="personalData-item">Správa sociálnych sietí</li>
                            <li className="personalData-item">Organizácia</li>
                            <li className="personalData-item">Kontrola</li>
                            <li className="personalData-item">Preklady</li>
                        </ul>
                    </li>
                </ul>

                <p className="personalData-text space-top">
                    <span className="underline italic">Internetová stránka:</span>
                    <br />
                    Internetovou stránkou sa myslí naša webová stránka www.lunema.sk.
                </p>
                <div className="subtitle">III. Objednávka služby / služieb a uzavretie zmluvy</div>

                <p className="personalData-text">
                    Poskytovateľ informuje Objednávateľa, Užívateľa a Návštevníka, že jednotlivé služby spolu nesúvisia a je potrebné si ich
                    objednať každú samostatne.
                    <br />
                    <br />
                    <span className="underline italic bold">Prenájom virtuálneho sídla (vrátane balíku služieb prináležiaci k sídlu)</span>
                    <br />
                    <br />
                    Záväzne objednať si službu/služby prostredníctvom internetovej stránky je možné len pri službe Prenájom virtuálneho
                    sídla (vrátane balíku služieb prináležiaci k sídlu) z dôvodu, že pri tejto službe je stanovená pevná cena v súlade so
                    zverejneným cenníkom na internetovej stránke.
                    <br />
                    <br />
                    Vyplnením objednávkového formulára vyjadrujete svoj súhlas s VOP.
                    <br />
                    <br />
                    Udelením súhlasu s VOP Objednávateľ udeľuje Poskytovateľovi súhlas so začatím poskytovania služby/služieb pred uplynutím
                    lehoty na odstúpenie. Objednávateľ potvrdzuje, že bol riadne poučený Poskytovateľom o tom, že udelením súhlasu so
                    začatím poskytovania služby/služieb pred uplynutím lehoty na odstúpenie od zmluvy stráca po úplnom poskytnutí
                    služby/služieb právo na odstúpenie od Zmluvy.
                    <br />
                    <br />
                    Poskytovateľ následne spracuje Objednávateľom zadané údaje potrebné k uzatvoreniu zmluvy podľa ustanovení § 269 ods. 2
                    zákona č. 513/1991 Zb. Obchodný zákonník v znení aktuálnych predpisov a iných aplikovateľných právnych predpisov a na
                    Objednávateľom zadaný mail pošle Poskytovateľ zálohovú faktúru a vzor Zmluvy o poskytovaní sídla. Následne vyzve
                    Objednávateľa ku kontrole osobných a iných dojednaných údajov a podpisu zmluvy na diaľku. Po úhrade a riadnom doručení
                    potrebných dokumentov zo strany Objednávateľa Poskytovateľovi, Poskytovateľ pošle súhlas so zriadením sídla s overeným
                    podpisom doporučene poštou na adresu Objednávateľa.
                    <br />
                    <br />
                    Zrealizovaní objednávky, uhradením zálohovej faktúry a podpísaním zmluvy Objednávateľ berie na vedomie, že udelením
                    súhlasu s VOP so začatím poskytovania služby/služieb pred uplynutím lehoty na odstúpenie od zmluvy stráca po úplnom
                    poskytnutí služby/služieb právo na odstúpenie od zmluvy, v súlade s ustanovením § 4 ods. 6 zákona č. 102/2014 Zb.
                    o ochrane spotrebiteľa pri predaji tovaru alebo poskytovaní služby na základe zmluvy uzavretej na diaľku alebo zmluvy
                    uzavretej mimo prevádzkových priestorov predávajúceho a o zmene a doplnení niektorých zákonov v znení aktuálnych
                    predpisov.
                    <br />
                    <br />
                    Zmluvné strany sa dohodli, že Objednávateľ je povinný uhradiť Poskytovateľovi cenu za skutočne poskytnuté plnenie podľa
                    ustanovením § 10 ods. 5 zákona č. 102/2014 Zb. Zákon o ochrane spotrebiteľa pri predaji tovaru alebo poskytovaní služby
                    na základe zmluvy uzavretej na diaľku alebo zmluvy uzavretej mimo prevádzkových priestorov predávajúceho a o zmene
                    a doplnení niektorých zákonov v znení aktuálnych predpisov, t.j. cenu za skutočne poskytnuté plnenie Poskytovateľom do
                    dňa doručenia oznámenia o odstúpení od Zmluvy, ak Objednávateľ odstúpi od Zmluvy po tom, čo udelil Poskytovateľovi
                    súhlas v zmysle podľa § 4 ods. 6 zákona 102/2014 Zb. Zákon o ochrane spotrebiteľa pri predaji tovaru alebo poskytovaní
                    služby na základe zmluvy uzavretej na diaľku alebo zmluvy uzavretej mimo prevádzkových priestorov predávajúceho
                    a o zmene a doplnení niektorých zákonov v znení aktuálnych predpisov.
                    <br />
                    <br />
                    Objednávka prostredníctvom internetovej stránky / mailu je archivovaná v elektronickej podobe a nie je verejne
                    prístupná.
                    <br />
                    <br />
                </p>
                <p className="personalData-text">
                    <span className="underline italic bold">Účtovníctvo a služby virtuálnej asistentky</span>
                    <br />
                    <br />
                    Služby účtovníctva a služby virtuálnej asistentky sú na internetovej stránke uvedené za účelom reklamy podnikateľskej
                    činnosti Poskytovateľa. Všetky obchodné a zmluvné podmienky sa dohadujú individuálne po presnom zadefinovaní objemu
                    a rozsahu prác a po vzájomnej dohode oboch zmluvných strán. Obchodný vzťah sa následne riadi dohodnutými zmluvnými
                    podmienkami.
                </p>

                <div className="subtitle">IV. Cenník</div>
                <p className="personalData-text space-top">
                    <span className="underline italic bold">Prenájom virtuálneho sídla (vrátane balíku služieb prináležiaci k sídlu)</span>
                    <br />
                    <br />
                    Aktuálny cenník služby/služieb je uvedený na internetovej stránke Poskytovateľa www.lunema.sk. Cena služieb sa platí
                    paušálne ročne, vopred na základe zálohovej faktúry vyhotovenej Poskytovateľom. Registrácia sídla / miesta podnikania je
                    minimálne na 12 mesiacov.
                    <br />
                    <br />
                    Po úhrade zálohovej faktúry je Poskytovateľom zaslaný riadny daňový doklad (faktúra), v ktorom je zúčtovaná platba
                    Objednávateľa.
                    <br />
                    <br />
                    Objednávateľ týmto udeľuje súhlas so zaslaním faktúry elektronicky, ktorá sa doručuje na mailovú adresu Objednávateľa,
                    ktorú Poskytovateľovi poskytol pri objednaní služby/služieb. Poskytovateľ si vyhradzuje právo zmeniť Cenník. Zmena
                    cenníka nemá žiaden vplyv na skôr prijaté/potvrdené objednávky.
                    <br />
                    <br />V prípade neuhradenia odplaty Objednávateľom za službu/služby v zmysle cenníka Poskytovateľ nie je povinný
                    poskytovať službu/služby, ktoré si Objednávateľ objednal, a/alebo Poskytovateľ a Objednávateľ dohodol.
                </p>
                <p className="personalData-text space-top">
                    <span className="underline italic bold">Účtovníctvo a služby virtuálnej asistentky</span>
                    <br />
                    <br />
                    Cena za služby účtovníctva a služby virtuálnej asistentky je stanovená individuálne po zadaní úloh a zistenia rozsahu a
                    náročnosti práce.
                </p>

                <div className="subtitle">V. Záverečné ustanovenia</div>
                <p className="personalData-text">
                    Súčasťou VOP sú aj reklamačné podmienky, ochrana osobných údajov a cookies, ktoré sú samostatne zverejnené na
                    internetovej stránke www.lunema.sk.
                    <br />
                    <br />
                    Pre vylúčenie akýchkoľvek pochybností, obsahom služby/služieb nie je poskytovanie právnych služieb podľa osobitných
                    predpisov (napr. podľa zákona č. 586/2003 Zb. o advokácii v znení neskorších predpisov) a poskytovanie informácií nemá
                    charakter právneho poradenstva, informácie majú iba všeobecný charakter a sú z verejne prístupných zdrojov.
                    <br />
                    <br />
                    Napriek tomu, že sa Poskytovateľ v maximálnej miere snaží o to, aby informácie, ktoré sú obsahom internetovej stránky
                    boli presné a prehľadné, nepreberá akúkoľvek právnu zodpovednosť za akékoľvek vyhlásenie, ktoré je na nich prezentované.
                    Poskytovateľ nenesie voči tretím stranám zodpovednosť za priame, nepriame alebo mimoriadne škody, spôsobené použitím
                    informácií v súvislosti s poskytnutím služby/služieb a/alebo informácii uvedených na internetovej stránke.
                    <br />
                    <br />
                    Poskytovateľ upozorňuje, že informácie uvedené na internetovej stránke môžu byť aktualizované bez predchádzajúceho
                    upozornenia a že je oprávnený meniť rozsah a ceny poskytovaných služieb uvedených na internetovej stránke. Takáto zmena
                    sa nevzťahuje na už potvrdené objednávky a pre nich zostáva v platnosti pôvodné znenie VOP, vrátane cenníka.
                    Poskytovateľ nezodpovedá za škodu spôsobenú Objednávateľovi najmä, nie však výlučne, ak Objednávateľ uviedol
                    Dodávateľovi nepravdivé alebo neúplné údaje, alebo neposkytol bezodkladne Poskytovateľovi dostatočnú súčinnosť pri
                    poskytovaní služby/služieb, alebo akýmkoľvek spôsobom pozmenil obsah univerzálnych dokumentov, alebo neposkytol všetky
                    potrebné dokumenty v potrebnom počte, forme alebo obsahu a i.
                    <br />
                    <br />
                    Objednávateľ zodpovedá za pravdivosť, správnosť a úplnosť identifikačných údajov a osobných údajov, ktoré uvedie v
                    súvislosti s poskytovanou službou Poskytovateľovi. V prípade, ak Objednávateľ poskytne Poskytovateľovi údaje tretej
                    osoby, je povinný tak konať len na základe osobitného právneho vzťahu s touto treťou osobou alebo je povinný zabezpečiť
                    si predchádzajúci súhlas tejto tretej osoby so zadaním jej identifikačných údajov alebo osobných údajov v súvislosti s
                    poskytovaním služby/služieb.
                    <br />
                    <br />
                </p>

                <p className="personalData-text space-top">
                    Zverejnené 2.1.2024, s účinnosťou od 2.1.2024, na dobu neurčitú.
                </p>
            </div>
            <CookieConsent
                location="bottom"
                buttonText="Rozumiem"
                disableStyles={true}
                style={{
                    background: '#2B373B',
                    color: 'var(--main-color-orange)',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'fixed',
                    width: '100%',
                    alignItems: 'center',
                    padding: '0.5rem 0'
                }}
                buttonStyle={{
                    backgroundColor: 'var(--main-color-orange)',
                    textAlign: 'center',
                    color: 'var(--main-color-brown)',
                    fontSize: '1rem',
                    width: '5.6rem',
                    height: '1.7rem',
                    border: 'none',
                    borderRadius: '0.5rem',
                    margin: '0.5rem 0 0.2rem 0',
                    cursor: 'pointer'
                }}
                expires={365}
            >
                Prevádzkovateľ oboznamuje Užívateľa i Návštevníka, že webová stránka používa len základné (funkčné) súbory Cookies. Bez
                týchto Cookies nemôžeme poskytovať služby, ktoré tvoria základ našej stránky. Pokiaľ tieto Cookies zakážete, nebudeme môcť
                zabezpečiť bezchybný chod stránky. Iné Cookies nezbierame.
            </CookieConsent>
        </div>
    );
}

export default BusinessCondPage;
