import React from 'react';
import companyImg from '../../images/company.svg';
import translatingImg from '../../images/translating.svg';
import controlImg from '../../images/search.svg';
import socialMediaImg from '../../images/social-media.svg';
import administrationImg from '../../images/calendar.svg';
import organizationImg from '../../images/process.svg';
import homeImg from '../../images/home.svg';
import accountingImg from '../../images/accounting.svg';
import callImg from '../../images/call-center.svg';
import './Services.css';

function Services(props: { click: boolean; setClick: React.Dispatch<React.SetStateAction<boolean>> }) {
    return (
        <div className="services">
            <div className="services-title main-title">Služby</div>
            <div className="services-container services-container--desctop">
                {!props.click && (
                    <div className="services-home services-box">
                        <img src={homeImg} alt="home" className="services-icon" />
                        <div className="title">Prenájom sídla</div>
                        <p>
                            Virtuálne sídlo obľubujú hlavne drobní živnostníci či spoločnosti s ručením obmedzeným. Je to ideálna
                            alternatíva, ak nemáte fyzickú kanceláriu alebo prevádzku a nechcete mať túto oficiálnu podnikateľskú adresu
                            u Vás doma. Toto <span>sídlo sa zapisuje do Obchodného registra </span>spoločnosti (miesto podnikania sa
                            zapisuje do Živnostenského registra živnostníka) a daný subjekt ho používa na všetkých úradných dokumentoch.
                        </p>
                    </div>
                )}

                {!props.click && (
                    <div className="services-accounting services-box">
                        <img src={accountingImg} className="services-icon" />
                        <div className="title">Účtovníctvo</div>
                        <p>
                            Ponuka <span>vedenia účtovníctva</span> pre fyzické a právnické osoby,{' '}
                            <span>spracovanie daňových priznaní</span>, ekonomicko – účtovné <span>poradenstvo</span> na elimináciu možných
                            rizík súvisiacich s podnikaním.
                            <br />
                            <span> Správa bytových a nebytových objektov</span>, vrátane spracovania ročných vyúčtovaní spotreby energií.
                        </p>
                    </div>
                )}

                <div className="services-call services-box" id="cube" onClick={() => props.setClick(!props.click)}>
                    <img src={callImg} className="services-icon" />
                    <div className="title">Virtuálna asistentka</div>
                    <p>
                        <span>Efektívne nahradí zamestnanca</span> vo Vašej firme a <span>ušetrí Vám stovky eur</span>.{' '}
                        <span>Preberie za Vás úlohy</span>, ktoré nestíhate alebo neviete a 
                        <span>zaplatíte za ňu len toľko, koľko hodín v mesiaci ju reálne potrebujete</span>. Nemusíte jej dávať mesačnú
                        mzdu, ani platiť za ňu odvody.
                    </p>
                    <button className="click" id="click" onClick={() => props.setClick(!props.click)}>
                        {(!props.click && `Bližšie informácie`) || (props.click && `Späť`)}
                    </button>
                </div>

                {props.click && (
                    <div className="assistant-container">
                        <div className="assistant-item company">
                            <img src={companyImg} className="assestant-img" />
                            <p>
                                <span className="green">
                                    Úrady
                                    <br />
                                </span>
                                &#9900; Vyzdvihnúť / podať rôzne dokumenty na úradoch
                                <br />
                                (okres ZH, ZV, BB, ZC, BS)
                            </p>
                        </div>
                        <div className="assistant-item administration">
                            <img src={administrationImg} className="assestant-img" />
                            <p>
                                <span className="green">
                                    Administratíva
                                    <br />
                                </span>
                                &#9900; Pripraviť rôzne žiadosti, oznámenia, pozvánky
                                <br />
                                &#9900; Napísať rôzne zmluvy (kúpne, nájomné, ...)
                                <br />
                                &#9900; Vystaviť faktúry
                                <br />
                                &#9900; Prepísať text
                            </p>
                        </div>
                        <div className="assistant-item social-media">
                            <img src={socialMediaImg} className="assestant-img" />
                            <p>
                                <span className="green">
                                    Sociálne siete
                                    <br />
                                </span>
                                &#9900; Spravovať sociálne siete a web stránky
                                <br />
                                &#9900; Online marketing
                                <br />
                                &#9900; Vytvoriť jednoduché web stránky
                            </p>
                        </div>
                        <div className="assistant-item organization">
                            <img src={organizationImg} className="assestant-img" />
                            <p>
                                <span className="green">
                                    Organizácia
                                    <br />
                                </span>
                                &#9900; Zorganizovať pracovné porady
                                <br />
                                &#9900; Zabezpečiť školenia
                                <br />
                                &#9900; Rezervácia ubytovania
                            </p>
                        </div>
                        <div className="assistant-item control">
                            <img src={controlImg} className="assestant-img" />
                            <p>
                                <span className="green">
                                    Kontrola
                                    <br />
                                </span>
                                &#9900; Strážiť dôležité termíny, aby ste na nič nezabudli
                                <br />
                                &#9900; Kontrola úhrady faktúr
                            </p>
                        </div>
                        <div className="assistant-item translating">
                            <img src={translatingImg} className="assestant-img" />
                            <p>
                                <span className="green">
                                    Preklady
                                    <br />
                                </span>
                                &#9900; Preloženie písaných textov z / do anglického jazyka
                            </p>
                        </div>
                    </div>
                )}
            </div>

            <div className="services-container services-container--mobile">
                {
                    <div className="services-home services-box">
                        <img src={homeImg} alt="home" className="services-icon" />
                        <div className="title">Prenájom sídla</div>
                        <p className="services-description">
                            Virtuálne sídlo obľubujú hlavne drobní živnostníci či spoločnosti s ručením obmedzeným. Je to ideálna
                            alternatíva, ak nemáte fyzickú kanceláriu alebo prevádzku a nechcete mať túto oficiálnu podnikateľskú adresu
                            u Vás doma. Toto <span>sídlo sa zapisuje do Obchodného registra </span>spoločnosti (miesto podnikania sa
                            zapisuje do Živnostenského registra živnostníka) a daný subjekt ho používa na všetkých úradných dokumentoch.
                        </p>
                    </div>
                }

                {
                    <div className="services-accounting services-box">
                        <img src={accountingImg} className="services-icon" />
                        <div className="title">Účtovníctvo</div>
                        <p className="services-description">
                            Ponuka <span>vedenia účtovníctva</span> pre fyzické a právnické osoby,{' '}
                            <span>spracovanie daňových priznaní</span>, ekonomicko – účtovné <span>poradenstvo</span> na elimináciu možných
                            rizík súvisiacich s podnikaním.
                            <span> Správa bytových a nebytových objektov</span>, vrátane spracovania ročných vyúčtovaní spotreby energií.
                        </p>
                    </div>
                }

                <div className="services-call services-box" id="cube" onClick={() => props.setClick(!props.click)}>
                    <img src={callImg} className="services-icon" />
                    <div className="title">Virtuálna asistentka</div>
                    <p className="services-description">
                        <span>Efektívne nahradí zamestnanca</span> vo Vašej firme a <span>ušetrí Vám stovky eur</span>.{' '}
                        <span>Preberie za Vás úlohy</span>, ktoré nestíhate alebo neviete a 
                        <span>zaplatíte za ňu len toľko, koľko hodín v mesiaci ju reálne potrebujete</span>. Nemusíte jej dávať mesačnú
                        mzdu, ani platiť za ňu odvody.
                    </p>
                    <button className="click" id="click" onClick={() => props.setClick(!props.click)}>
                        {(!props.click && `Bližšie informácie`) || (props.click && `Skryť`)}
                    </button>
                    {props.click && (
                        <div className="assistant-container">
                            <div className="assistant-item company">
                                <img src={companyImg} className="assestant-img" />
                                <p>
                                    <span className="green">
                                        Úrady
                                        <br />
                                    </span>
                                    &#9900; Vyzdvihnúť / podať rôzne dokumenty na úradoch
                                    <br />
                                    (okres ZH, ZV, BB, ZC, BS)
                                </p>
                            </div>
                            <div className="assistant-item administration">
                                <img src={administrationImg} className="assestant-img" />
                                <p>
                                    <span className="green">
                                        Administratíva
                                        <br />
                                    </span>
                                    &#9900; Pripraviť rôzne žiadosti, oznámenia, pozvánky
                                    <br />
                                    &#9900; Napísať rôzne zmluvy (kúpne, nájomné,...)
                                    <br />
                                    &#9900; Vystaviť faktúry
                                    <br />
                                    &#9900; Prepísať text
                                </p>
                            </div>
                            <div className="assistant-item social-media">
                                <img src={socialMediaImg} className="assestant-img" />
                                <p>
                                    <span className="green">
                                        Sociálne siete
                                        <br />
                                    </span>
                                    &#9900; Spravovať sociálne siete a web stránky
                                    <br />
                                    &#9900; Online marketing
                                    <br />
                                    &#9900; Vytvoriť jednoduché web stránky
                                </p>
                            </div>
                            <div className="assistant-item organization">
                                <img src={organizationImg} className="assestant-img" />
                                <p>
                                    <span className="green">
                                        Organizácia
                                        <br />
                                    </span>
                                    &#9900; Zorganizovať pracovné porady
                                    <br />
                                    &#9900; Zabezpečiť školenia
                                    <br />
                                    &#9900; Rezervácia ubytovania
                                </p>
                            </div>
                            <div className="assistant-item control">
                                <img src={controlImg} className="assestant-img" />
                                <p>
                                    <span className="green">
                                        Kontrola
                                        <br />
                                    </span>
                                    &#9900; Strážiť dôležité termíny, aby ste na nič nezabudli
                                    <br />
                                    &#9900; Kontrola úhrady faktúr
                                </p>
                            </div>
                            <div className="assistant-item translating">
                                <img src={translatingImg} className="assestant-img" />
                                <p>
                                    <span className="green">
                                        Preklady
                                        <br />
                                    </span>
                                    &#9900; Preloženie písaných textov z / do anglického jazyka
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Services;
