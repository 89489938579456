import React, { useState } from "react";
import Menu from "../menu/Menu";
import Foot from "../foot/Foot";
import Formular from "../formular/Formular";
import yes from "../../images/yes.svg"
import Ad from "../Ad/Ad"
import "./PackagePage.css";
import CookieConsent from "react-cookie-consent";

function BasicPackagePage() {

  const [isOrdered, setIsOrdered] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  console.log(isOrdered);

  return (
    <div className="packagePage">
      <Menu mobileMenu={mobileMenu} setMobileMenu={setMobileMenu} />
      {!mobileMenu &&
        <div>
          <div className={isOrdered ? "enable-display" : "main-title title-margin"}>Základný balík<span className="mobile-item mobile-title"> - zhrnutie</span></div>
          <div className={isOrdered ? "enable-display" : "content-box"}>
            <div className="summary-box">
              <div className="summary-list">
                <ul className='services-list summary-01'>
                  <li className='services-item'>Súhlas so zriadením sídla / miesta podnikania</li>
                  <li className='services-item'>Označenie poštovej schránky</li>
                  <li className='services-item'>Preberanie listových zásielok (obyčajných)</li>
                  <li className='services-item'>Archivácia neprevzatých dokladov max. 1 rok</li>
                  <li className='services-item services-item--last'>Cena spolu / mesiac<span className="mobile-item">: 5 &euro;</span></li>
                  <li className='services-item services-item--last'>Cena spolu / rok<span className="mobile-item">: 60 &euro;</span></li>
                </ul>

                <ul className='check-list-01 summary-02'>
                  <li className='check-item '><img className='check-img' src={yes} /></li>
                  <li className='check-item '><img className='check-img' src={yes} /></li>
                  <li className='check-item'><img className='check-img' src={yes} /></li>
                  <li className='check-item'><img className='check-img' src={yes} /></li>
                  <li className='check-item check-item--last'>5 &euro;</li>
                  <li className='check-item check-item--last'>60 &euro;</li>
                </ul>
              </div>
            </div>
            <Formular package="základný" price="5" setIsOrdered={setIsOrdered} />
          </div>
          <div className={isOrdered ? "half-height" : ""}>
            <div className={isOrdered ? "main-title title-center" : "enable-display"}>Ďakujeme za objednávku</div>
            <p className={isOrdered ? "title text-center" : "enable-display"}>Poslali sme Vám potvrdzujúci email</p>
            <Ad />
          </div>

          <Foot bottom={isOrdered} />
        </div>}

      <CookieConsent
        location="bottom"
        buttonText="Rozumiem"
        disableStyles={true}
        style={{ background: "#2B373B", color: "var(--main-color-orange)", textAlign: "center", display: "flex", flexDirection: "column", position: "fixed", width: "100%", alignItems: "center", padding: "0.5rem 0", }}
        buttonStyle={{ backgroundColor: "var(--main-color-orange)", textAlign: "center", color: "var(--main-color-brown)", fontSize: "1rem", width: "5.6rem", height: "1.7rem", border: "none", borderRadius: "0.5rem", margin: "0.5rem 0 0.2rem 0", cursor: "pointer" }}
        expires={365}
      >
        Prevádzkovateľ oboznamuje Užívateľa i Návštevníka, že webová stránka používa len základné (funkčné) súbory Cookies. Bez týchto Cookies nemôžeme poskytovať služby, ktoré tvoria základ našej stránky. Pokiaľ tieto Cookies zakážete, nebudeme môcť zabezpečiť bezchybný chod stránky. Iné Cookies nezbierame.
      </CookieConsent>
    </div>
  )
};

export default BasicPackagePage;