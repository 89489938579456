import React from 'react';
import CookieConsent from 'react-cookie-consent';

function CookiesPage() {
    return (
        <div>
            <div className="personalData-box">
                <div className="main-title title-light">Cookies</div>
                <div className="subtitle">I. Identifikačné údaje Poskytovateľa</div>
                <p className="personalData-text">
                    LUNEMA property, s. r. o.
                    <br />
                    IČO: 55319971, DIČ: 2121947421, IČ DPH: SK2121947421 podľa §7a
                    <br />
                    sídlo: Hviezdoslavova 281/18, 965 01 Žiar nad Hronom, Slovenská republika
                    <br />
                    zápis spoločnosti: Obchodný register Okresný súd Banská Bystrica, oddiel: Sro, vložka č. 45985/S
                    <br />
                    IBAN (číslo účtu): SK83 8330 0000 0022 0257 1003, BIC: FIOZSKBA
                    <br />
                    konajúca za spoločnosť: Ing. Mariana Hrmová – konateľ
                    <br />
                    kontakt: lunema.sro@gmail.com , +421907149549
                </p>
                <div className="subtitle">II. Základné ustanovenia</div>
                <p className="personalData-text">
                    Poskytovateľ oboznamuje Objednávateľa a/alebo Užívateľa či Návštevníka tejto internetovej stránky, že na stránke sa
                    používajú len základné (funkčné) súbory cookies. Sú to súbory, ktoré nepoškodzujú váš počítač / iné médium, neobsahujú
                    vírusy. Tieto súbory sú nevyhnutné, aby ste sa mohli pohybovať po našej internetovej stránke a využívať jej funkcie.
                    Spomínané základné súbory cookies tvoria základ pre prevádzku našej internetovej stránky a umožňujú používanie
                    základných funkcií. K základným cookies patria napríklad predvyplnenie formulárov.
                    <br />
                    <br />
                    Bez týchto cookies nemôžeme poskytovať služby, ktoré tvoria základ našej internetovej stránky. Pokiaľ tieto cookies
                    zakážete, nebudeme môcť zabezpečiť bezchybný chod našej internetovej stránky. Iné cookies nezbierame. Spomínané súbory
                    cookies o vás nezhromažďujú žiadne informácie na marketingové, preferenčné, štatistické účely, ani iné okrem tých vyššie
                    uvedených. Stále ale máte možnosť zabrániť zbieraniu našich základných súborov cookies a to tak, že buď opustíte našu
                    internetovú stránku, alebo zmenou nastavenia vo svojom prehliadači.
                    <br />
                    <br />
                    Zároveň upozorňujeme Objednávateľa, Užívateľa či Návštevníka, že na našej internetovej stránke sú odkazy na iné
                    internetové stránky, ktoré môžu zbierať aj iné ako základné (funkčné) súbory cookies. Prekliknutím sa na inú internetovú
                    stránku z našej internetovej stránky vás daná stránka vyzve k povoleniu ich vlastných cookies.
                    <br />
                    <br />
                </p>
                <p className="personalData-text space-top">
                Zverejnené 2.1.2024, s účinnosťou od 2.1.2024, na dobu neurčitú.
                </p>
            </div>
            <CookieConsent
                location="bottom"
                buttonText="Rozumiem"
                disableStyles={true}
                style={{
                    background: '#2B373B',
                    color: 'var(--main-color-orange)',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'fixed',
                    width: '100%',
                    alignItems: 'center',
                    padding: '0.5rem 0'
                }}
                buttonStyle={{
                    backgroundColor: 'var(--main-color-orange)',
                    textAlign: 'center',
                    color: 'var(--main-color-brown)',
                    fontSize: '1rem',
                    width: '5.6rem',
                    height: '1.7rem',
                    border: 'none',
                    borderRadius: '0.5rem',
                    margin: '0.5rem 0 0.2rem 0',
                    cursor: 'pointer'
                }}
                expires={365}
            >
                Prevádzkovateľ oboznamuje Užívateľa i Návštevníka, že webová stránka používa len základné (funkčné) súbory Cookies. Bez
                týchto Cookies nemôžeme poskytovať služby, ktoré tvoria základ našej stránky. Pokiaľ tieto Cookies zakážete, nebudeme môcť
                zabezpečiť bezchybný chod stránky. Iné Cookies nezbierame.
            </CookieConsent>
        </div>
    );
}

export default CookiesPage;
