import React, { useState } from 'react';
import './Quotes.css';
import nextImg from '../../images/next.svg';
import prevImage from '../../images/prev.svg';

function Quotes() {
    const q_arr: string[] = [
        'Neveril som, ale virtuálna asistentka mi naozaj šetrí čas a peniaze. Radosť s Vami spolupracovať.',
        'Nevnímali ma len ako „ďalší v poradí“. Počúvali ma vždy s porozumením, aby mi čo najlepšie vedeli poradiť v ekonomicko – účtovnej oblasti, vrátane spracovania daňového priznania. ',
        'Sú nielen milí a ochotní, ale cením si aj ich vysokú odbornosť a lojálnosť.',
        'Radi využívame ich služby ohľadom virtuálneho sídla. Pomer ich cien a kvality je jedničkou na trhu.'
    ];
    const author_arr: string[] = ['Lukáš', 'Barbora', 'Firma Žiar nad Hronom', 'Konateľ Marián'];

    const [start, setStart] = useState(0);
    const [startMobile, setStartMobile] = useState(0);

    return (
        <div id="qu">
            <div className="quotes-container">
                <div className="button-container">
                    {start > 0 && (
                        <button
                            className="button-next"
                            onClick={() => {
                                if (start === 0) {
                                    setStart(0);
                                } else {
                                    setStart(start - 1);
                                }
                            }}
                        >
                            <img className="image-next left" src={prevImage} />
                        </button>
                    )}
                </div>

                {q_arr
                    .filter((item, id) => id >= start && id < start + 3)
                    .map((quote, inx) => (
                        <div className="quote-container" key={inx}>
                            <q className="quote">{quote}</q>
                            <div className="author">{author_arr.at(inx + start)}</div>
                        </div>
                    ))}

                <div className="button-container">
                    {start < q_arr.length - 3 && (
                        <button
                            className="button-next"
                            onClick={() => {
                                if (start === q_arr.length - 3) {
                                    setStart(start);
                                } else {
                                    setStart(start + 1);
                                }
                            }}
                        >
                            <img className="image-next right" src={nextImg} />
                        </button>
                    )}
                </div>
            </div>

            <div className="quotes-container--mobile">
                <div className="button-container">
                    {startMobile > 0 && (
                        <button
                            className="button-next"
                            onClick={() => {
                                if (startMobile === 0) {
                                    setStartMobile(0);
                                } else {
                                    setStartMobile(startMobile - 1);
                                }
                            }}
                        >
                            <img className="image-next left" src={prevImage} />
                        </button>
                    )}
                </div>

                {q_arr
                    .filter((item, id) => id >= startMobile && id < startMobile + 1)
                    .map((quote, inx) => (
                        <div className="quote-container" key={inx}>
                            <q className="quote">{quote}</q>
                            <div className="author">{author_arr.at(inx + startMobile)}</div>
                        </div>
                    ))}

                <div className="button-container">
                    {startMobile < q_arr.length - 1 && (
                        <button
                            className="button-next"
                            onClick={() => {
                                if (startMobile === q_arr.length - 1) {
                                    setStartMobile(startMobile);
                                } else {
                                    setStartMobile(startMobile + 1);
                                }
                            }}
                        >
                            <img className="image-next right" src={nextImg} />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Quotes;
