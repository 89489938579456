import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import App from './App';
import ErrorPage from './components/ErrorPage/ErrorPage';
import BasicPackagePage from './components/packagePages/BasicPackagePage';
import MediumPackagePage from './components/packagePages/MediumPackagePage';
import ExpandedPackagePage from './components/packagePages/ExpandedPackagePage';
import PersonalDataPage from './components/infoPages/PersonalDataPage';
import BusinessCondPage from './components/infoPages/BusinessCondPage';
import ComplaintCondPage from './components/infoPages/ComplaintCondPage';
import CookiesPage from './components/infoPages/CookiesPage';

const router = createHashRouter([
  {
    path: '/',
    element: <App/>,
    errorElement: <ErrorPage/>,
  },
  {
    path: '/formular-zakladny',
    element: <BasicPackagePage/>,
    errorElement: <ErrorPage/>,
  },
  {
    path: '/formular-stredny',
    element: <MediumPackagePage/>,
    errorElement: <ErrorPage/>,
  },
  {
    path: '/formular-rozsireny',
    element: <ExpandedPackagePage/>,
    errorElement: <ErrorPage/>,
  },
  {
    path: '/ochrana-udajov',
    element: <PersonalDataPage/>,
    errorElement: <ErrorPage/>,
  },
  {
    path: '/obchodne-podmienky',
    element: <BusinessCondPage/>,
    errorElement: <ErrorPage/>,
  },
  {
    path: '/reklamacne-podmienky',
    element: <ComplaintCondPage/>,
    errorElement: <ErrorPage/>,
  },
  {
    path: '/cookies',
    element: <CookiesPage/>,
    errorElement: <ErrorPage/>,
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
