import React from 'react';
import './PersonalDataPage.css';
import CookieConsent from 'react-cookie-consent';

function ComplaintCondPage() {
    return (
        <div>
            <div className="personalData-box">
                <div className="main-title title-light">Reklamačné podmienky</div>
                <div className="subtitle">I. Poskytovateľ</div>
                <p className="personalData-text">
                    LUNEMA property, s. r. o.
                    <br />
                    IČO: 55319971, DIČ: 2121947421, IČ DPH: SK2121947421 podľa §7a
                    <br />
                    sídlo: Hviezdoslavova 281/18, 965 01 Žiar nad Hronom, Slovenská republika
                    <br />
                    zápis spoločnosti: Obchodný register Okresný súd Banská Bystrica, oddiel: Sro, vložka č. 45985/S
                    <br />
                    IBAN (číslo účtu): SK83 8330 0000 0022 0257 1003, BIC: FIOZSKBA
                    <br />
                    konajúca za spoločnosť: Ing. Mariana Hrmová – konateľ
                    <br />
                    kontakt: lunema.sro@gmail.com , +421907149549
                </p>
                <div className="subtitle">II. Základné ustanovenia</div>
                <p className="personalData-text">
                    Reklamáciu služby/služieb treba podať bezodkladne, najlepšie v lehote do 14 dní odo dňa kedy Objednávateľovi vznikol
                    nárok na reklamáciu. Reklamáciu je potrebné doručiť písomne doporučenou formou na adresu sídla Poskytovateľa najneskôr v
                    posledný deň, kedy má Objednávateľ právo podať reklamáciu. V reklamácii je potrebné uviesť kontaktné údaje, dôvod a
                    dôkaz reklamácie.
                    <br />
                    <br />
                    Reklamácie Poskytovateľ vybavuje spravidla do 14 dní odo dňa prijatia reklamácie v súlade s VOP a všeobecne záväznými
                    právnymi predpismi SR.
                </p>
                <div className="subtitle">III. Reklamačné konanie</div>
                <p className="personalData-text">Poskytovateľ informuje Objednávateľa, že reklamačné konanie upravujú tieto zákony:</p>
                <ul className="personalData-text first-ul">
                    <li className="personalData-item">Obchodný zákonník – Zákon č. 513/1991 Zb. v znení aktuálnych predpisov</li>
                    <li className="personalData-item">Občianskym zákonníkom – Zákon č. 40/1964 Zb. v znení aktuálnych predpisov</li>
                    <li className="personalData-item">
                        Zákonom o ochrane spotrebiteľa – Zákon č. 250/2007 Zb. v znení aktuálnych predpisov
                    </li>
                    <li className="personalData-item">
                        Zákon o ochrane spotrebiteľa pri predaji tovaru alebo poskytovaní služieb na základe zmluvy uzavretej na diaľku
                        alebo zmluvy uzavretej mimo prevádzkových priestorov predávajúceho a o zmene a doplnení niektorých zákonov – Zákon
                        č. 102/2014 Zb. v znení aktuálnych predpisov
                    </li>
                    <li className="personalData-item">Zákonom o priestupkoch – Zákon č. 372/1990 Z. z. v znení aktuálnych predpisov</li>
                    <li className="personalData-item">
                        Zákonom, ktorým sa mení a dopĺňa Zákon o ochrane spotrebiteľa a Zákon o priestupkoch – Zákon č. 397/2008 Z. z. v
                        znení aktuálnych predpisov
                    </li>
                    <li className="personalData-item">
                        Zákonom o alternatívnom riešení spotrebiteľských sporov – Zákon č. 391/2015 Z. z. v znení aktuálnych predpisov
                    </li>
                </ul>
                <div className="subtitle">IV. Alternatívne riešenie sporu</div>
                <p className="personalData-text">
                    Objednávateľ má právo obrátiť sa na Poskytovateľa so žiadosťou o nápravu, ak nie je spokojný so spôsobom, ktorým
                    Poskytovateľ vybavil jeho reklamáciu, alebo ak sa domnieva, že Poskytovateľ porušil jeho práva. Objednávateľ má právo
                    podať návrh na začatie alternatívneho riešenia sporu, ak Poskytovateľ na žiadosť o nápravu odpovedal zamietavo, alebo na
                    ňu neodpovedal do 30 dní odo dňa jej odoslania.
                    <br />
                    <br />
                    <span className="underline italic">Orgánom alternatívneho riešenia sporov je:</span>
                    <br />
                    https://www.soi.sk/sk/alternativne-riesenie-spotrebitelskych-sporov.soi
                    <br />
                    <br />
                    <span className="underline italic">Adresa na doručovanie:</span>
                    <br />
                    Slovenská obchodná inšpekcia
                    <br />
                    ústredný inšpektorát
                    <br />
                    Odbor pre medzinárodné vzťahy a alternatívne riešenie spotrebiteľských sporov
                    <br />
                    Bajkalská 21/A, p. p. 29
                    <br />
                    827 99 Bratislava 27
                    <br />
                    <br />
                    <span className="underline italic">Adresy na podávanie podaní v elektronickej podobe:</span>
                    <br />
                    ars@soi.sk , adr@soi.sk
                    <br />
                    <br />
                    <span className="underline italic">Telefonické číslo:</span>
                    <br />
                    +421 2/58272123, +421 2/58272141
                    <br />
                    <br />
                    Objednávateľ môže využiť na podanie návrhu na začatie mimosúdneho riešenia sporov a na získanie informácií o mimosúdnom
                    riešení spotrebiteľských sporov (vrátane cezhraničných sporov) tiež internetovú stránku:
                    https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=SK
                </p>
                <p className="personalData-text space-top">
                    Zverejnené 2.1.2024, s účinnosťou od 2.1.2024, na dobu neurčitú.
                </p>
            </div>
            <CookieConsent
                location="bottom"
                buttonText="Rozumiem"
                disableStyles={true}
                style={{
                    background: '#2B373B',
                    color: 'var(--main-color-orange)',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'fixed',
                    width: '100%',
                    alignItems: 'center',
                    padding: '0.5rem 0'
                }}
                buttonStyle={{
                    backgroundColor: 'var(--main-color-orange)',
                    textAlign: 'center',
                    color: 'var(--main-color-brown)',
                    fontSize: '1rem',
                    width: '5.6rem',
                    height: '1.7rem',
                    border: 'none',
                    borderRadius: '0.5rem',
                    margin: '0.5rem 0 0.2rem 0',
                    cursor: 'pointer'
                }}
                expires={365}
            >
                Prevádzkovateľ oboznamuje Užívateľa i Návštevníka, že webová stránka používa len základné (funkčné) súbory Cookies. Bez
                týchto Cookies nemôžeme poskytovať služby, ktoré tvoria základ našej stránky. Pokiaľ tieto Cookies zakážete, nebudeme môcť
                zabezpečiť bezchybný chod stránky. Iné Cookies nezbierame.
            </CookieConsent>
        </div>
    );
}

export default ComplaintCondPage;
