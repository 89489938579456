import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import yes from '../../images/yes.svg';
import no from '../../images/no.svg';
import './PriceList.css';
import { handleClickScrollToTop } from '../../App';

function PriceList(props: { click: boolean }) {
    const navigate = useNavigate();

    const navigateToPage = (url: string) => {
        navigate(url);
        handleClickScrollToTop();
    };

    const selectButtonPath = (option: number) => {
        if (option == 5) {
            return '/formular-zakladny';
        } else if (option == 8) {
            return 'formular-stredny';
        } else {
            return 'formular-rozsireny';
        }
    };

    const [option, setOption] = useState(5);

    return (
        <div className="prices">
            <div className="prices-title main-title">Cenník</div>
            {!props.click && (
                <div className="desctop-table">
                    <div className="prices-box">
                        <ul className="services-list">
                            <li className="services-item services-item--first">Balík služieb súvisiacich s prenájmom sídla *</li>
                            <li className="services-item">Súhlas so zriadením sídla / miesta podnikania **</li>
                            <li className="services-item">Označenie poštovej schránky</li>
                            <li className="services-item">Preberanie listových zásielok (obyčajných)</li>
                            <li className="services-item">Archivácia neprevzatých dokladov max. 1 rok</li>
                            <li className="services-item">
                                Preposlanie listových zásielok mailom (neotvorené) –{' '}
                                <span
                                    id="small-item-long"
                                    style={{
                                        fontSize: '0.8rem',
                                        fontWeight: 'unset'
                                    }}
                                >
                                    1x týždenne
                                </span>
                            </li>
                            <li className="services-item">
                                Preposlanie lístku o uložení zásielky na pošte mailom –{' '}
                                <span
                                    id="small-item-long"
                                    style={{
                                        fontSize: '0.8rem',
                                        fontWeight: 'unset'
                                    }}
                                >
                                    1x týždenne
                                </span>
                            </li>
                            <li className="services-item servise-item--long">
                                Preposlanie listových zásielok na dohodnutú adresu (doporučene) –{' '}
                                <span
                                    id="small-item-long"
                                    style={{
                                        fontSize: '0.8rem',
                                        fontWeight: 'unset'
                                    }}
                                >
                                    2x mesačne
                                </span>
                            </li>
                            <li className="services-item">
                                Preposlanie obsahu listových zásielok mailom (otvorené) –{' '}
                                <span
                                    id="small-item-long"
                                    style={{
                                        fontSize: '0.8rem',
                                        fontWeight: 'unset'
                                    }}
                                >
                                    1x týždenne
                                </span>
                            </li>
                            <li className="services-item services-item--last">Cena spolu / mesiac</li>
                        </ul>

                        <ul className="check-list-01">
                            <li className="check-item check-item--first">Základný</li>
                            <li className="check-item ">
                                <img className="check-img" src={yes} />
                            </li>
                            <li className="check-item ">
                                <img className="check-img" src={yes} />
                            </li>
                            <li className="check-item">
                                <img className="check-img" src={yes} />
                            </li>
                            <li className="check-item">
                                <img className="check-img" src={yes} />
                            </li>
                            <li className="check-item">
                                <img className="check-img" src={no} />
                            </li>
                            <li className="check-item">
                                <img className="check-img" src={no} />
                            </li>
                            <li className="check-item">
                                <img className="check-img" src={no} />
                            </li>
                            <li className="check-item">
                                <img className="check-img" src={no} />
                            </li>
                            <li className="check-item check-item--last">5 &euro;</li>
                            <li className="check-item">
                                <button className="click check-click" onClick={() => navigateToPage('/formular-zakladny')}>
                                    Mám záujem
                                </button>
                            </li>
                        </ul>

                        <ul className="check-list-02">
                            <li className="check-item check-item--first">Stredný</li>
                            <li className="check-item">
                                <img className="check-img" src={yes} />
                            </li>
                            <li className="check-item">
                                <img className="check-img" src={yes} />
                            </li>
                            <li className="check-item">
                                <img className="check-img" src={yes} />
                            </li>
                            <li className="check-item">
                                <img className="check-img" src={yes} />
                            </li>
                            <li className="check-item">
                                <img className="check-img" src={yes} />
                            </li>
                            <li className="check-item">
                                <img className="check-img" src={yes} />
                            </li>
                            <li className="check-item">
                                <img className="check-img" src={no} />
                            </li>
                            <li className="check-item">
                                <img className="check-img" src={no} />
                            </li>
                            <li className="check-item check-item--last">8 &euro;</li>
                            <li className="check-item">
                                <button className="click check-click" onClick={() => navigateToPage('/formular-stredny')}>
                                    Mám záujem
                                </button>
                            </li>
                        </ul>

                        <ul className="check-list-03">
                            <li className="check-item check-item--first">Rozšírený</li>
                            <li className="check-item">
                                <img className="check-img" src={yes} />
                            </li>
                            <li className="check-item">
                                <img className="check-img" src={yes} />
                            </li>
                            <li className="check-item">
                                <img className="check-img" src={yes} />
                            </li>
                            <li className="check-item">
                                <img className="check-img" src={yes} />
                            </li>
                            <li className="check-item">
                                <img className="check-img" src={yes} />
                            </li>
                            <li className="check-item">
                                <img className="check-img" src={yes} />
                            </li>
                            <li className="check-item">
                                <img className="check-img" src={yes} />
                            </li>
                            <li className="check-item">
                                <img className="check-img" src={yes} />
                            </li>
                            <li className="check-item check-item--last">13 &euro;</li>
                            <li className="check-item">
                                <button className="click check-click" onClick={() => navigateToPage('/formular-rozsireny')}>
                                    Mám záujem
                                </button>
                            </li>
                        </ul>
                    </div>
                    <p className="prices-ps">* Platí sa paušálne ročne, vopred</p>
                    <p className="prices-ps">** Registrácia sídla / miesta podnikania min. na 12 mesiacov</p>
                </div>
            )}

            <div className="assistantPrice-box desctop-table">
                <div className="assistantPrice-item services-item--first">Vedenie učtovníctva a služby virtuálnej asistentky</div>
                <div className="assistantPrice-item">Cenník je stanovený individuálne po zadaní úloh a zistenia náročnosti práce ***</div>
            </div>
            <div className="desctop-table">
                <p className="prices-ps" id="quotes">
                    *** Ak služba podlieha nákladom vynaložených predom (napr. poplatok na katastri), tieto poplatky su vybrané od klienta
                    predom.
                    <br /> V ostatných prípadoch je faktúra dodaná až po vykonaní prác.
                </p>
            </div>

            <div className="mobile-table mobile-display">
                <div className="service-item-mobile services-item--first">Balík služieb súvisiacich s prenájmom sídla *</div>
                <div className="services-options service-item-mobile">
                    <div className={`service-item-click color-5 ${option == 5 ? 'active' : ''}`} onClick={() => setOption(5)}>
                        Základný
                    </div>
                    <div className={`service-item-click color-8 ${option == 8 ? 'active' : ''}`} onClick={() => setOption(8)}>
                        Stredný
                    </div>
                    <div className={`service-item-click color-13 ${option == 13 ? 'active' : ''}`} onClick={() => setOption(13)}>
                        Rozšírený
                    </div>
                </div>

                <div className={`services-list-mobile color-${option}`}>
                    <div className="services-list-mobile--content">
                        <div className="service-item-mobile">Súhlas so zriadením sídla / miesta podnikania **</div>
                        <div className="service-item-mobile">Označenie poštovej schránky</div>
                        <div className="service-item-mobile">Preberanie listových zásielok (obyčajných)</div>
                        <div className="service-item-mobile">Archivácia neprevzatých dokladov max. 1 rok</div>
                        {option !== 5 && (
                            <div>
                                <div className="service-item-mobile">
                                    Preposlanie listových zásielok mailom (neotvorené) –{' '}
                                    <span
                                        id="small-item-long"
                                        style={{
                                            fontSize: '0.8rem',
                                            fontWeight: 'unset'
                                        }}
                                    >
                                        1x týždenne
                                    </span>
                                </div>
                                <div className="service-item-mobile">
                                    Preposlanie lístku o uložení zásielky na pošte mailom –{' '}
                                    <span
                                        id="small-item-long"
                                        style={{
                                            fontSize: '0.8rem',
                                            fontWeight: 'unset'
                                        }}
                                    >
                                        1x týždenne
                                    </span>
                                </div>
                                {option == 13 && (
                                    <div>
                                        {' '}
                                        <div className="service-item-mobile">
                                            Preposlanie listových zásielok na dohodnutú adresu (doporučene) –{' '}
                                            <span
                                                style={{
                                                    fontSize: '0.8rem',
                                                    fontWeight: 'unset'
                                                }}
                                                id="small-item-long"
                                            >
                                                2x mesačne
                                            </span>
                                        </div>
                                        <div className="service-item-mobile">
                                            Preposlanie obsahu listových zásielok mailom (otvorené) –{' '}
                                            <span
                                                id="small-item-long"
                                                style={{
                                                    fontSize: '0.8rem',
                                                    fontWeight: 'unset'
                                                }}
                                            >
                                                1x týždenne
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        <div className="service-item-mobile services-item--last">Cena spolu / mesiac: {option} &euro;</div>
                        <div className="center-button">
                            <button className="click check-click" onClick={() => navigateToPage(selectButtonPath(option))}>
                                Mám záujem
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="note mobile-display">
                <p className="note-p">* Platí sa paušálne ročne, vopred</p>
                <p className="note-p">** Registrácia sídla / miesta podnikania min. na 12 mesiacov</p>
            </div>

            <div className="mobile-table mobile-display space">
                <div className="assistantPrice-item-mobile services-item--first">Vedenie učtovníctva a služby virtuálnej asistentky</div>
                <div className="assistantPrice-item-mobile">
                    Cenník je stanovený individuálne po zadaní úloh a zistenia náročnosti práce ***
                </div>
            </div>

            <div className="note mobile-display">
                <p className="note-p" id="quotes">
                    *** Ak služba podlieha nákladom vynaložených predom (napr. poplatok na katastri), tieto poplatky su vybrané od klienta
                    predom. V ostatných prípadoch je faktúra dodaná až po vykonaní prác.
                </p>
            </div>
        </div>
    );
}

export default PriceList;
