import React, { useState } from "react";
import Menu from "../menu/Menu";
import Foot from "../foot/Foot";
import Formular from "../formular/Formular";
import yes from "../../images/yes.svg";
import Ad from "../Ad/Ad";
import CookieConsent from "react-cookie-consent";

function ExpandedPackagePage() {

  const [isOrdered, setIsOrdered] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);

  return (
    <div>
      <Menu mobileMenu={mobileMenu} setMobileMenu={setMobileMenu} />
      <div className={!isOrdered ? "main-title title-margin" : "enable-display"}>Rozšírený balík<span className="mobile-item mobile-title"> - zhrnutie</span></div>
      <div className={!isOrdered ? "content-box" : "enable-display"}>
        <div className="summary-box">
          <div className="summary-list">
            <ul className='services-list summary-01'>
              <li className='services-item'>Súhlas so zriadením sídla / miesta podnikania</li>
              <li className='services-item'>Označenie poštovej schránky</li>
              <li className='services-item'>Preberanie listových zásielok (obyčajných)</li>
              <li className='services-item'>Archivácia neprevzatých dokladov max. 1 rok</li>
              <li className='services-item'>Archivácia neprevzatých dokladov max. 1 rok</li>
              <li className='services-item services-item--long'>Preposlanie listových zásielok mailom<br />(neotvorené) – <span id='small-item-long' style={{
                fontSize: '0.8rem',
                fontWeight: 'unset',
              }}>1x týždenne</span></li>
              <li className='services-item services-item--long'>Preposlanie lístku o uložení zásielky na pošte<br />mailom – <span id='small-item-long' style={{
                fontSize: '0.8rem',
                fontWeight: 'unset',
              }}>1x týždenne</span></li>
              <li className='services-item services-item--long'>Preposlanie listových zásielok na dohodnutú<br />adresu (doporučene) – <span id='small-item-long' style={{
                fontSize: '0.8rem',
                fontWeight: 'unset',
              }}>2x mesačne</span></li>
              <li className='services-item services-item--long'>Preposlanie obsahu listových zásielok mailom<br />(otvorené) – <span id='small-item-long' style={{
                fontSize: '0.8rem',
                fontWeight: 'unset',
              }}>1x týždenne</span></li>
              <li className='services-item services-item--last'>Cena spolu / mesiac<span className="mobile-item">: 13 &euro;</span></li>
              <li className='services-item services-item--last'>Cena spolu / rok<span className="mobile-item">: 156 &euro;</span></li>
            </ul>

            <ul className='check-list-01 summary-02'>
              <li className='check-item '><img className='check-img' src={yes} /></li>
              <li className='check-item '><img className='check-img' src={yes} /></li>
              <li className='check-item'><img className='check-img' src={yes} /></li>
              <li className='check-item'><img className='check-img' src={yes} /></li>
              <li className='check-item'><img className='check-img' src={yes} /></li>
              <li className='check-item services-item--long'><img className='check-img' src={yes} /></li>
              <li className='check-item services-item--long'><img className='check-img' src={yes} /></li>
              <li className='check-item services-item--long'><img className='check-img' src={yes} /></li>
              <li className='check-item services-item--long'><img className='check-img' src={yes} /></li>
              <li className='check-item check-item--last'>13 &euro;</li>
              <li className='check-item check-item--last'>156 &euro;</li>
            </ul>
          </div>
        </div>
        <Formular package="rozšírený" price="13" setIsOrdered={setIsOrdered} />
      </div>
      <div className={isOrdered ? "half-height" : ""}>
        <div className={isOrdered ? "main-title title-center" : "enable-display"}>Ďakujeme za objednávku</div>
        <p className={isOrdered ? "title text-center" : "enable-display"}>Poslali sme Vám potvrdzujúci email</p>
        <Ad />
      </div>


      <Foot bottom={isOrdered} />
      <CookieConsent
        location="bottom"
        buttonText="Rozumiem"
        disableStyles={true}
        style={{ background: "#2B373B", color: "var(--main-color-orange)", textAlign: "center", display: "flex", flexDirection: "column", position: "fixed", width: "100%", alignItems: "center", padding: "0.5rem 0", }}
        buttonStyle={{ backgroundColor: "var(--main-color-orange)", textAlign: "center", color: "var(--main-color-brown)", fontSize: "1rem", width: "5.6rem", height: "1.7rem", border: "none", borderRadius: "0.5rem", margin: "0.5rem 0 0.2rem 0", cursor: "pointer" }}
        expires={365}
      >
        Prevádzkovateľ oboznamuje Užívateľa i Návštevníka, že webová stránka používa len základné (funkčné) súbory Cookies. Bez týchto Cookies nemôžeme poskytovať služby, ktoré tvoria základ našej stránky. Pokiaľ tieto Cookies zakážete, nebudeme môcť zabezpečiť bezchybný chod stránky. Iné Cookies nezbierame.
      </CookieConsent>
    </div>
  )
};

export default ExpandedPackagePage;