import React from 'react';
import './HomePage.css'

function HomePage() {
  return (

    <div className='homePage-content'>
      <div className='home-page-container'>
        <h1 className='homePage-caption'>Virtuálne sídlo</h1>
        <p className='homePage-description'>
          Prenájom sídla – tzv. virtuálne sídlo je služba, ktorú poskytujeme firmám alebo živnostníkom na
          adrese: <br /><span className='homePage-address'>Dr. Jánského 477/4, 965 01 Žiar nad Hronom, SK</span>
        </p>
      </div>
    </div>

  )
}

export default HomePage;